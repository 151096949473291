import React, { useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa"; // Import Chevron icons
import { FaArrowRight } from "react-icons/fa";


export const Pennycress = (props) => {
  const [showIsoSeq, setShowIsoSeq] = useState(false);
  const [showTranscriptomics, setShowTranscriptomics] = useState(true);
  const [showProteomics, setShowProteomics] = useState(false);
  const [showMetabolomics, setShowMetabolomics] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showPBC10, setShowPBC10] = useState(false);  
  const [showPBWT, setShowPBWT] = useState(false); 
  const [showPBC10WT, setShowPBC10WT] = useState(false);  
  const [showPZC10, setShowPZC10] = useState(false);  
  const [showPZWT, setShowPZWT] = useState(false); 
  const [showPZC10WT, setShowPZC10WT] = useState(false);
  const [showTC10, setShowTC10] = useState(false);  
  const [showTWT, setShowTWT] = useState(false); 
  const [showTC10WT, setShowTC10WT] = useState(false); 
  const [showEPBC10, setShowEPBC10] = useState(false);
  const [showEPZC10, setShowEPZC10] = useState(false);
  const [showETC10, setShowETC10] = useState(false);  
  
  return (
    <div id="organisms" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Pennycress</h2>
        </div>

          {/* IsoSeq Section */}
          <div className="org-title">
                    <h3
                      onClick={() => setShowIsoSeq(!showIsoSeq)}
                      style={{ cursor: "pointer" }}
                    >
                      PacBio IsoSeq{" "}
                      {showIsoSeq ? (
                        <FaChevronUp /> // Chevron Up when expanded
                      ) : (
                        <FaChevronDown /> // Chevron Down when collapsed
                      )}
                    </h3>
                  </div>
        {showIsoSeq && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Assembled sequences annotated with reference genome functional annotations, pathways, domains etc. information for same organism and Arabidopsis homologs, functional annotations, pathways etc.</p>
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>Pacbio Hifi IsoSeq </b>
              <p><a href="/files/Pennycress/Pennycress_Hifi_IsoSeq.fasta" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                Fasta file
            </a></p>
            <p><a href="/files/Pennycress/Pennycress_Hifi_IsoSeq.gff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                GFF file
            </a></p>
            
            <p><a href="/files/Pennycress/Pennycress_annotated_assembled_sequences.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
                Annotated Assembled Sequences
            </a></p>
            </div>
          </div>
        )}

        {/* Transcriptomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowTranscriptomics(!showTranscriptomics)}
            style={{ cursor: "pointer" }}
          >
            Transcriptomics{" "}
            {showTranscriptomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        
        <p style={{ fontStyle: 'italic' }}>Masterfile contains analyzed transcriptomics data, including gene identifiers (gene names, assembled sequence # etc.), gene expression (FPKM), differential expression results (pvalue, qvalue, log2 fold change), reference genome functional annotations, pathways, domains etc. information for same organism and Arabidopsis homologs, functional annotations, pathways etc.</p>
         <h5 style={{ marginTop: "30px" }}>All the below files are Developmental Stages - Jan 2025</h5>
        {showTranscriptomics && (
          <div className="row"  >
            <div className="row">
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px", color: "#d28a70" }}>Pacbio Hifi IsoSeq</b>
              <p style={{  marginTop:"3px",fontSize: "18px", color: "#d28a70" }}><FaArrowRight /></p>
            </div>
              <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>FLD10 (vs FLD10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/IsoSeq/FLD10_Pennycress_with_Hifi_Isoseq_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowPBC10(!showPBC10)}
              style={{ cursor: "pointer" }}
            >
              FLD10_Comparisons{" "}
              {showPBC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showPBC10 && (
              <>   
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD15_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD20_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD25_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD30_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD35_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLDM_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_FLD10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>WT (vs WT10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/IsoSeq/WT_Pennycress_with_Hifi_Isoseq_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowPBWT(!showPBWT)}
              style={{ cursor: "pointer" }}
            >
              WT_Comparisons{" "}
              {showPBWT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showPBWT && (
              <>     
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/WT15_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT15_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/WT20_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT20_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/WT25_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT25_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/WT30_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT30_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/WT35_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT35_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/WTM_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WTM_vs_WT10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>FLD10 vs WT</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/IsoSeq/FLD10_vs_WT_Pennycress_with_Hifi_Isoseq_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowPBC10WT(!showPBC10WT)}
              style={{ cursor: "pointer" }}
            >
              FLD10_vs_WT_Comparisons{" "}
              {showPBC10WT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showPBC10WT && (
              <>     
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD10_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD10_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD15_vs_WT15.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_WT15
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD20_vs_WT20.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_WT20
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD25_vs_WT25.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_WT25
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD30_vs_WT30.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_WT30
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD35_vs_WT35.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_WT35
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Pacbio/FLD10_vs_WT_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLDM_vs_WTM.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_WTM
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "16px"}}>Engineered Cuphea Genes</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/IsoSeq/FLD10_Pennycress_with_Hifi_Isoseq_and_4_Cuphea_Seqs_Master_File.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowEPBC10(!showEPBC10)}
              style={{ cursor: "pointer" }}
            >
              FLD10_Comparisons{" "}
              {showEPBC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showEPBC10 && (
              <>   
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD15_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD20_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD25_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD30_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLD35_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Hifi_Isoseq_Transcripts/FLDM_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_FLD10
            </a></p>
            </>
            )}
            </div>
            </div>
            <div className="row">
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px", color: "#d28a70"}}>Thlaspi Arvense(v1.1) Phytozome Reference <FaArrowRight /></b>
              {/* <p style={{ fontSize: "18px", color: "#d28a70" }}><FaArrowRight /></p> */}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>FLD10 (vs FLD10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Tarvense/FLD10_Pennycress_with_Reference_v1_1_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowPZC10(!showPZC10)}
              style={{ cursor: "pointer" }}
            >
              FLD10_Comparisons{" "}
              {showPZC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showPZC10 && (
              <>   
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD15_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD20_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD25_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD30_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD35_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLDM_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_FLD10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>WT (vs WT10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Tarvense/WT_Pennycress_with_Reference_v1_1_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowPZWT(!showPZWT)}
              style={{ cursor: "pointer" }}
            >
              WT_Comparisons{" "}
              {showPZWT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showPZWT && (
              <>     
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/WT15_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT15_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/WT20_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT20_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/WT25_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT25_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/WT30_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT30_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/WT35_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT35_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/WTM_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WTM_vs_WT10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>FLD10 vs WT</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Tarvense/FLD10_vs_WT_Pennycress_with_Reference_v1_1_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowPZC10WT(!showPZC10WT)}
              style={{ cursor: "pointer" }}
            >
              FLD10_vs_WT_Comparisons{" "}
              {showPZC10WT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showPZC10WT && (
              <>     
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD10_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD10_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD15_vs_WT15.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_WT15
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD20_vs_WT20.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_WT20
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD25_vs_WT25.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_WT25
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD30_vs_WT30.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_WT30
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD35_vs_WT35.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_WT35
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Phytozome/FLD10_vs_WT_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLDM_vs_WTM.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_WTM
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "16px"}}>Engineered Cuphea Genes</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Tarvense/FLD10_Pennycress_with_Reference_v1_1_and_4_Cuphea_Seqs_Master_File.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowEPZC10(!showEPZC10)}
              style={{ cursor: "pointer" }}
            >
              FLD10_Comparisons{" "}
              {showEPZC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showEPZC10 && (
              <>   
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD15_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD20_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD25_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD30_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLD35_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Phytozome_Thlaspi_Arvense_v1_1/FLDM_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_FLD10
            </a></p>
            </>
            )}
            </div>
            </div>
            <div className="row">
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px", color: "#d28a70"}}>Trinity De Novo</b>
              <p style={{ marginTop:"3px",fontSize: "18px", color: "#d28a70" }}><FaArrowRight /></p>
            </div>
              <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>FLD10 (vs FLD10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Trinity/FLD10_Pennycress_with_Trinity_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowTC10(!showTC10)}
              style={{ cursor: "pointer" }}
            >
              FLD10_Comparisons{" "}
              {showTC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showTC10 && (
              <>  
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD15_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD20_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD25_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD30_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD35_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLDM_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_FLD10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>WT (vs WT10)</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Trinity/WT_Pennycress_with_Trinity_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowTWT(!showTWT)}
              style={{ cursor: "pointer" }}
            >
              WT_Comparisons{" "}
              {showTWT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showTWT && (
              <>   
            <p><a href="/files/Pennycress/cuff_diff/Trinity/WT_Pennycress_RNAseq_with_Trinity_Transcripts/WT15_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT15_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/WT_Pennycress_RNAseq_with_Trinity_Transcripts/WT20_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT20_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/WT_Pennycress_RNAseq_with_Trinity_Transcripts/WT25_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT25_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/WT_Pennycress_RNAseq_with_Trinity_Transcripts/WT30_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT30_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/WT_Pennycress_RNAseq_with_Trinity_Transcripts/WT35_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WT35_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/WT_Pennycress_RNAseq_with_Trinity_Transcripts/WTM_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            WTM_vs_WT10
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "18px"}}>FLD10 vs WT</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Trinity/FLD10_vs_WT_Pennycress_with_Trinity_Master_File.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowTC10WT(!showTC10WT)}
              style={{ cursor: "pointer" }}
            >
              FLD10_vs_WT_Comparisons{" "}
              {showTC10WT ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showTC10WT && (
              <>     
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLD10_vs_WT10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD10_vs_WT10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLD15_vs_WT15.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_WT15
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLD20_vs_WT20.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_WT20
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLD25_vs_WT25.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_WT25
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLD30_vs_WT30.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_WT30
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLD35_vs_WT35.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_WT35
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Trinity/FLD10_vs_WT_Pennycress_RNAseq_with_Trinity_Transcripts/FLDM_vs_WTM.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_WTM
            </a></p>
            </>
            )}
            </div>
            <div className="col-xs-6" style={{ width: "20%", marginBottom: "20px" }}>
              <b style={{ fontSize: "16px"}}>Engineered Cuphea Genes</b>
              <p style={{ fontSize: "16px"}}><a href="/files/Pennycress/Trinity/FLD10_Pennycress_with_Trinity_and_4_Cuphea_Seqs_Master_File.xlsx" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer",  fontWeight: "bold" }}>
                Master file
            </a></p>
            <p
              onClick={() => setShowETC10(!showETC10)}
              style={{ cursor: "pointer" }}
            >
              FLD10_Comparisons{" "}
              {showETC10 ? (
                <FaChevronUp /> // Chevron Up when expanded
              ) : (
                <FaChevronDown /> // Chevron Down when collapsed
              )}
            </p>
            {showETC10 && (
              <>   
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD15_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD15_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD20_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD20_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD25_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD25_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD30_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD30_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLD35_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLD35_vs_FLD10
            </a></p>
            <p><a href="/files/Pennycress/cuff_diff/Engineered_cuphea/FLD10_Pennycress_RNAseq_with_Trinity_Transcripts/FLDM_vs_FLD10.diff" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer" }}>
            FLDM_vs_FLD10
            </a></p>
            </>
            )}
            </div>
            </div>
            </div>
        )}

        {/* Proteomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowProteomics(!showProteomics)}
            style={{ cursor: "pointer" }}
          >
            Proteomics{" "}
            {showProteomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
         
        </div>
        {showProteomics && (
          <div className="row">
             <p style={{ fontStyle: 'italic' }}>Master file contains analyzed proteomics data, including protein groups and expression values for each of the 6 replicates across 7 time points for 2 comparisons (FLD10 and WT).</p>
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              {/* <b style={{ fontSize: "18px"}}>Proteomics Master File</b> */}
              <b style={{ fontSize: "18px"}}><a href="/files/Pennycress/Pennycress_Proteomics.csv" download style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontWeight: "bold" }}>
              Proteomics Master File
            </a></b>
            </div>
          </div>
        )}

        {/* Metabolomics Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowMetabolomics(!showMetabolomics)}
            style={{ cursor: "pointer" }}
          >
            Metabolomics{" "}
            {showMetabolomics ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showMetabolomics && (
          <div className="row">
            <div className="col-xs-18 col-md-12" style={{ marginBottom: "20px" }}>
              <p>No Data</p>
            </div>
          </div>
        )}

        {/* Tools Section */}
        <div className="org-title">
          <h3
            onClick={() => setShowTools(!showTools)}
            style={{ cursor: "pointer" }}
          >
            Tools{" "}
            {showTools ? (
              <FaChevronUp /> // Chevron Up when expanded
            ) : (
              <FaChevronDown /> // Chevron Down when collapsed
            )}
          </h3>
        </div>
        {showTools && (
          <div className="row">
            <p style={{ fontStyle: 'italic' }}>Coming Soon...</p>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p><a href="https://kbcommons.org/system/home/Tarvense" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                KBCommons
            </a></p>
            </div>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p><a href="https://kbcommons.org/system/tools/blast/Tarvense" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                Blast
            </a></p>
            </div>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p>
              {/* <a href="https://kbcommons.org/system/browse/diff_exp/Pennycress" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}> */}
                3D Omics
            {/* </a> */}
            </p>
            </div>
            <div className="col-xs-8 col-md-3" style={{ marginBottom: "20px" }}>
            <p>
              {/* <a href="https://kbcommons.org/system/browse/ccmt" target="_blank" style={{ textDecoration: "none", color: "#007bff", cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}> */}
                CCMT
            {/* </a> */}
            </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
